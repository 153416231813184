import moment from "moment";
import { combineReducers } from '@reduxjs/toolkit';
import  { configureStore } from '@reduxjs/toolkit';
import filtersReducer from '../reducers/filters';
import expensesReducer from '../reducers/expenses';
import authReducer from "../reducers/auth";

const devTools = process.env.NODE_ENV !== "production"
//reducers
const rootReducer = combineReducers({
    expenses: expensesReducer,
    filters: filtersReducer,
    auth: authReducer
});

//preload state
const preloadedState = {
    expenses: [],
    filters: {
        searchTerm: '',
        sortBy: 'date',
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month')
    }
}

//combineReducers is not needed as configureStore calls it internally
export default () => {
    return configureStore({
        reducer : rootReducer,
        preloadedState,
        devTools, //connect to redux devtool    extension
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: ['SET_START_DATE', 'SET_END_DATE'],
                    ignoredPaths: ['filters.startDate', 'filters.endDate']
                }
            })
    })
}

const createTestStore = (intialState = {}, reducer = {}) => {
    return configureStore({
        reducer,
        preloadedState: intialState,
        devTools: true
    })
}

export { createTestStore }

