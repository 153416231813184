
import moment from "moment";
// filter expenses by filter properties
const getVisibleExpenses = (expenses, { searchTerm, sortBy, startDate, endDate }) => {
    return expenses.filter((expense) => {
        const textMatch = expense.description.toLowerCase().includes(searchTerm.toLowerCase());
        const startDateMatch = startDate ? startDate.isSameOrBefore(moment(expense.createdAt), 'day'): true
        const endDateMatch = endDate ? endDate.isSameOrAfter(moment(expense.createdAt), 'day'): true

        return textMatch && startDateMatch && endDateMatch;
    }).sort((a,b) => {
        if(sortBy === 'date'){
            // sort expenses by date descending
            return a.createdAt > b.createdAt ? -1 : 1;
        }else{
            //sort expenses by amount descending
            return a.amount > b.amount ? -1 : 1;
        }
    })
}

export default getVisibleExpenses;