import React from 'react';
import { SingleDatePicker } from "react-dates";
import moment from 'moment';
class ExpenseForm extends React.Component {
    //es6 class property
    state = {
        description: this.props.expense ? this.props.expense.description : '',
        amount: this.props.expense ? (this.props.expense.amount).toString() / 100 : '',
        note: this.props.expense ? this.props.expense.note : '',
        createdAt: this.props.expense ? moment(this.props.expense.createdAt) : moment(),
        focused: false,
        error: undefined
    }
    onAmountChange = (e) => {
        const amount = e.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            this.setState(() => ({ amount }))
        }
    }
    onDescriptionChange = (e) => {
        const description = e.target.value;
        this.setState(() => ({ description }))
    }

    onNoteChange = (e) => {
        const note = e.target.value;
        this.setState(() => ({ note }))
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        if (!this.state.description || !this.state.amount) {
            const message = 'Please provide at least a description and an amount!';
            this.setState(() => ({ error: message }))
        } else {
            this.setState(() => ({ error: undefined }))
            this.props.onSubmit({
                description: this.state.description,
                amount: parseFloat(this.state.amount, 10) * 100, //convert to cents
                note: this.state.note,
                createdAt: this.state.createdAt.valueOf()
            })
        }


    }
    onDateChange = (createdAt) => {
        if (createdAt) {
            this.setState(() => ({ createdAt }))
        }

    }

    onFocusChange = ({ focused }) => {
        this.setState(() => ({ focused }))
    }
    render() {
        return (
            <form className='form' 
                onSubmit={this.onFormSubmit}>
                {this.state.error && <p className="form__error" data-testid='error'>{this.state.error}</p>}
                <input
                    className='text-input'
                    value={this.state.description}
                    type="text"
                    placeholder='Description'
                    autoFocus
                    onChange={this.onDescriptionChange}
                />
                <input
                    className='text-input'
                    type='text'
                    placeholder='Amount'
                    value={this.state.amount}
                    onChange={this.onAmountChange}
                />
                <SingleDatePicker
                    date={this.state.createdAt}
                    onDateChange={this.onDateChange}
                    focused={this.state.focused}
                    onFocusChange={this.onFocusChange}
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    firstDayOfWeek={1}
                    displayFormat="DD.MM.YYYY"
                    id="CreatedAtDatePicker"
                    block
                />
                <textarea
                    className='textarea'
                    value={this.state.note}
                    placeholder='Add a note for your expense (optional)'
                    onChange={this.onNoteChange}
                />
                <div>
                <button className="button button--action" data-testid='submit-button'>{this.props.expense ? 'Update expense' : 'Add expense'}</button>
                </div>
            </form>
        )
    }
}
// const ExpenseForm = (props) => {
//     const [expense, setExpense] = useState(props.expense.id ? props.expense : {
//         description: '',
//         note: ''
//     })
//     return (
//         <form>
//             <input
//                 value={expense.description}
//                 type="text"
//                 placeholder='Description'
//                 autoFocus
//                 onChange={(e) => { setExpense((prevState) => ({
//                     ...prevState,
//                     description: e.target.value
//                 })) }}
//             />
//             <input
//                 type='currency'
//                 placeholder='Amount'
//             />
//             <textarea
//                 value={expense.note}
//                 placeholder='Add a note for your expense (optional)'
//                 onChange={(e) => setExpense((prevState) => ({ 
//                     ...prevState,
//                     note: e.target.value
//                 }))}
//             />
//             <button>Add expense</button>
//         </form>
//     )
// }

const mapStoreStateToComponentProps = (state, props) => {
    console.log(state);
    console.log(props)

    return {
        expense: { ...state.expenses.filter(expense => expense.id === props.router.params.id) }
    }
}
export default ExpenseForm;
