import React from "react";
import { connect } from "react-redux";
import { startLogin } from "../actions/auth";
const LoginPage = ({ startLogin }) => {
    return (
        <div className="box-layout">
        <div className="box-layout__box">
        <h1 className="box-layout__title">Expensify</h1>
        <p>Get your expenses under control</p>
            <button className="button" onClick={() => {
                startLogin();
            }}><span className="material-icons md-36">login</span>Login with Google</button>
          </div>
        </div>)
}

const mapDispatchToProps = (dispatch) => ({
    startLogin: () => dispatch(startLogin()),
})
export { LoginPage }
export default connect(undefined, mapDispatchToProps)(LoginPage);