import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import getVisibleExpenses from '../selectors/expenses';
import selectExpensesTotal from '../selectors/expense-total';
const ExpensesSummary = (props) => (
    <div className='page-header'>
        <div className='content-container'>
            <h1 className='page-header__title'>Viewing <span>{props.expenseCount}</span> {props.expenseCount === 1 ? "expense " : "expenses "}
                with a total of <span>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((props.expenseTotal / 100))}</span>
            </h1>
            <div className='page-header__actions'>
                <Link className="button button--action"to="/create">Add expense</Link>
            </div>
        </div>

    </div>
);
const mapStoreStateToComponentState = (state) => ({
    expenseCount: getVisibleExpenses(state.expenses, state.filters).length,
    expenseTotal: selectExpensesTotal(getVisibleExpenses(state.expenses, state.filters))
})
export { ExpensesSummary };
export default connect(mapStoreStateToComponentState)(ExpensesSummary);

