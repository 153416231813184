import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Header from '../components/Header';

const PrivateRoute = ({ 
    children, 
    component: Component, 
    redirect, 
    ...rest } ) => {
    const isAuthenticated = useSelector((state) => !!state.auth.uid ) 
    return  isAuthenticated 
            ? (  Component 
                ?
                <div>
                <Header />
                <Component {...rest} /> 
                </div> 
                : children
            ) 
            : (
            <Navigate to={redirect} />
            )    
}
export default PrivateRoute