//ActionTypes
const ExpenseActions = {
    ADD_EXPENSE: 'ADD_EXPENSE',
    REMOVE_EXPENSE: 'REMOVE_EXPENSE',
    EDIT_EXPENSE: 'EDIT_EXPENSE',
    SET_EXPENSES: 'SET_EXPENSES'
}

const FilterActions = {
    SET_TEXT_FILTER: 'SET_TEXT_FILTER',
    SORT_BY_DATE: 'SORT_BY_DATE',
    SORT_BY_AMOUNT: 'SORT_BY_AMOUNT',
    SET_START_DATE: 'SET_START_DATE',
    SET_END_DATE: 'SET_END_DATE'
}

export { ExpenseActions, FilterActions };