import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import AppRouter from './routers/AppRouter';
import LoadingSpinner from './components/LoadingSpinner';
import { startSetExpenses } from './actions/expenses'
import configureStore from './store/configureStore';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { browserHistory } from './routers/AppRouter';
import { login, logout } from './actions/auth';
import 'react-dates/initialize';
//Styles
import 'normalize.css/normalize.css';
import './styles/styles.scss';
import "react-dates/lib/css/_datepicker.css"
const store = configureStore();

//App.js
// returns <Routes>{children}</Routes>
// AppRouter returns <BrowserRouter><App.js>
const appRoot = createRoot(document.getElementById('root'));
const app = (
    <Provider store={store}>
        <AppRouter />
    </Provider>
)

let hasRendered = false;
appRoot.render(app);
function renderApp() {
    if (!hasRendered) {
        appRoot.render(app);
        hasRendered = true;
    }
}
appRoot.render(<LoadingSpinner />)
onAuthStateChanged(getAuth(), (user) => {
    if (user) {
        store.dispatch(login(user.uid))
        store.dispatch(startSetExpenses()).then(() => {
            renderApp();
            if (browserHistory.location.pathname === '/') {
                browserHistory.push('/dashboard')
            }
        })
    } else {
        store.dispatch(logout())
        renderApp();
        browserHistory.push('/')
    }
})


