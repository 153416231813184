import { ExpenseActions } from "./ActionTypes";
import { database } from '../api/firebase';
import { ref, push, remove, get, update, child } from 'firebase/database';

//Expense Action Generators

const addExpense = (expense = {}) => (
    {
        type: ExpenseActions.ADD_EXPENSE,
        expense
    });

const startAddExpense = (expenseData = {}) => {
    return (dispatch, getState) => {
        const {
            description = '',
            note = '',
            amount = 0,
            createdAt = 0
        } = expenseData;
        const expense = {
            description,
            amount,
            note,
            createdAt
        }
        const uid = getState().auth.uid;
        return push(ref(database, `users/${uid}/expenses`), expense).then((ref) => {
            dispatch(addExpense({
                id: ref.key,
                ...expense
            }))
        })
    }
}

const removeExpense = (id) => ({
    type: ExpenseActions.REMOVE_EXPENSE,
    id
})

const startRemoveExpense = (id) => async (dispatch, getState ) => {
    const uid = getState().auth.uid;
    try {
        await remove(ref(database, `users/${uid}/expenses/${id}`));
        dispatch(removeExpense(id))
    } catch (e) {
        // do nothing
        console.log(e)
    }
}


const editExpense = (id, updates) => ({
    type: ExpenseActions.EDIT_EXPENSE,
    id,
    updates

})

const startEditExpense = (id, updates) => (dispatch, getState) => {
    const uid = getState().auth.uid;
    return update(ref(database,  `users/${uid}/expenses/${id}`), updates).then(() => {
        dispatch(editExpense(id, updates))
    })
}
const setExpenses = (expenses = []) => ({
    type: ExpenseActions.SET_EXPENSES,
    expenses
})

const startSetExpenses = () => async (dispatch, getState) => {
    const uid = getState().auth.uid;
    try {
        const expenses = [];
        const snapshot = await get(child(ref(database), `users/${uid}/expenses`));
        snapshot.forEach(child => {
            expenses.push({
                id: child.key,
                ...child.val()
            })
        })
        dispatch(setExpenses(expenses));
    } catch (e) {
        //do nothing
    }
}





export {
    addExpense,
    editExpense,
    removeExpense,
    startAddExpense,
    setExpenses,
    startSetExpenses,
    startRemoveExpense,
    startEditExpense
};