import React from "react";
import { connect } from "react-redux";
import Expense from "./Expense";
import selectExpenses from '../selectors/expenses';
const ExpenseList = (props) => (
    <div className="content-container">
        <div className="list-header">
            <div className="show-for-mobile">Expenses</div>
            <div className="show-for-desktop">Expense</div>
            <div className="show-for-desktop">Amount</div>
        </div>
        <div className="list-item__body">
            {props.expenses.length === 0
                ? <div className="list-item list-item--message">
                    <span>No expenses</span>
                </div>
                : props.expenses.map((expense) => {
                    return <Expense key={expense.id} {...expense} />
                })}
        </div>

    </div>
)
const mapStoreStateToComponentProps = (state) => ({
    expenses: selectExpenses(state.expenses, state.filters)
})

//named export for testing which is disconnected from Redux
export { ExpenseList };
export default connect(mapStoreStateToComponentProps)(ExpenseList);