import moment from "moment";
import { FilterActions } from "../actions/ActionTypes";
const filterReducerDefaultState = {
    searchTerm: '',
    sortBy: 'date',
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month')
}
const filtersReducer = (state = filterReducerDefaultState, action) => {
    switch (action.type) {
        case FilterActions.SET_TEXT_FILTER:
            return {
                ...state,
                searchTerm: action.searchTerm
            }
        case FilterActions.SORT_BY_DATE:
            return {
                ...state,
                sortBy: 'date'
            }
        case FilterActions.SORT_BY_AMOUNT:
            return {
                ...state,
                sortBy: 'amount'
            }
        case FilterActions.SET_START_DATE:
            return {
                ...state,
                startDate: action.startDate
            }
        case FilterActions.SET_END_DATE:
            return {
                ...state,
                endDate: action.endDate
            }
        default:
            return state
    }

}

export default filtersReducer;