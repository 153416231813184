import { ExpenseActions } from "../actions/ActionTypes";

const expensesReducer = (state = [], action) => {
    switch (action.type) {
        case ExpenseActions.ADD_EXPENSE:
            return [...state, action.expense];
        case ExpenseActions.EDIT_EXPENSE:
            return state.map((expense) => {
                if (expense.id === action.id) {
                    //apply updates
                    return {
                        ...expense,
                        ...action.updates
                    }
                } else {
                    return expense; // do nothing
                }
            });
        case ExpenseActions.REMOVE_EXPENSE:
            return state.filter((expense) => expense.id !== action.id);
        case ExpenseActions.SET_EXPENSES:
            return action.expenses
        default:
            return state;
    }
}

export default expensesReducer;