import React from "react";
import ExpenseForm from "./ExpenseForm";
import { addExpense, startAddExpense } from "../actions/expenses";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'

const AddExpensePage = (props) => {
    const history = useNavigate();
    const onSubmit = (expense) =>{
        props.addExpense(expense);
        history('/dashboard');
    } 
    return (
    <div >
        <div className="page-header">
        <div className="content-container">
        <h1 className="page-header__title">Add Expense</h1>
        </div>
        </div>
        <div className="content-container">
        <ExpenseForm
            onSubmit={onSubmit}
        />
        </div>
      
    </div>)
}


const mapDispatchToProps = (dispatch) => ({
    addExpense: (expense) => dispatch(startAddExpense(expense))
})
export { AddExpensePage }
export default connect(undefined, mapDispatchToProps)(AddExpensePage);