import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { GoogleAuthProvider } from 'firebase/auth';
// import { getAnalytics } from "firebase/analytics";
// import { getDatabase, onValue, ref, set, get, child, remove, update, push ,query, onChildChanged } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration  
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCRfbLlSlB0Pw5jFdqpGzr2FkTW18APKf8",
//   authDomain: "mock-database-65996.firebaseapp.com",
//   databaseURL: "https://mock-database-65996-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "mock-database-65996",
//   storageBucket: "mock-database-65996.appspot.com",
//   messagingSenderId: "624908830691",
//   appId: "1:624908830691:web:26bc210c164c8f020542d6"
// };
const App = initializeApp(firebaseConfig);
export const database = getDatabase(App);
export const googleAuthProvider = new GoogleAuthProvider();