import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales/de';
moment.locale('de');
numeral.locale('de');
const Expense = ({ id, description, note, amount, createdAt }) => (
    <Link className="list-item" to={`/edit/${id}`}>
        <div>
            <h3 className='list-item__title'>{description}</h3>
            <span className='list-item__subtitle'>{moment(createdAt).format('Do MMMM, YYYY')}</span>
        </div>
        <span className='list-item__note'>{note}</span>
        <h3 className='list-item__data'>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((amount / 100))}</h3>

    </Link>


);



export default Expense;