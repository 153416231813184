import { FilterActions } from "./ActionTypes";

//Filter Action Generators
const setTextFilter = (searchTerm = '') => ({
    type: FilterActions.SET_TEXT_FILTER,
    searchTerm
})

const sortByDate = () => ({
    type: FilterActions.SORT_BY_DATE,
})

const sortByAmount = () => ({
    type: FilterActions.SORT_BY_AMOUNT,
})

const setStartDate = (startDate) => ({
    type: FilterActions.SET_START_DATE,
    startDate

})
const setEndDate = (endDate) => ({
    type: FilterActions.SET_END_DATE,
    endDate

})

export {
    setTextFilter,
    setStartDate,
    setEndDate,
    sortByAmount,
    sortByDate
}