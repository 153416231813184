import { getAuth, signInWithPopup, signInWithRedirect, GoogleAuthProvider, signOut } from 'firebase/auth';
import { googleAuthProvider } from '../api/firebase';

const startLogin = () => {
    return dispatch => {
         const auth = getAuth();
         return signInWithPopup(auth, googleAuthProvider)
    }
}

const login = (uid) => ({
    type: 'LOGIN',
    uid 
})
const startLogout = () => {
    return dispatch => {
         return signOut(getAuth())
    }
}

const logout = () => ({
    type: 'LOGOUT'
})

export { startLogin, startLogout, login, logout }