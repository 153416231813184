import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
const PublicRoute = ( { 
    children, 
    component: Component, 
    redirect } ) => {
        const isAuthenticated = useSelector( state => !!state.auth.uid )
        return isAuthenticated
                ? (
                    <Navigate to={redirect} />
                )
                : (
                    Component ? <Component /> : children
                )
    }

export default PublicRoute;