import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import ExpenseForm from "./ExpenseForm";
import { connect, useSelector } from 'react-redux';
import { editExpense, startEditExpense, startRemoveExpense } from "../actions/expenses";
const EditExpensePage = (props) => {
    const params = useParams();
    const history = useNavigate();
    const expense = useSelector(state => state.expenses.find((expense => expense.id === params.id)));
    return (
        <div>
            <div className="page-header">
                <div className="content-container">
                    <h1 className="page-header__title">Edit expense</h1>
                </div>
            </div>
            <div className="content-container">
            <ExpenseForm
            expense={expense} 
            onSubmit={ updatedExpense => {
                props.editExpense(expense.id, updatedExpense);
                history('/dashboard')
            }} />
             <button
                className="button button--remove" 
                onClick={() => { 
                        props.removeExpense(expense.id)
                        history('/dashboard');
                }}>Remove
             </button>
            </div>
           
        </div>
    )
}


 const mapDispatchToProps = (dispatch) => ({
    editExpense: (id, updates) => dispatch(startEditExpense(id, updates)),
    removeExpense: (id) => dispatch(startRemoveExpense(id))
 })

export { EditExpensePage } // for testing purposes
export default connect(undefined, mapDispatchToProps)(EditExpensePage);
