import React from 'react';
import { Routes, Route, HistoryRouter } from 'react-router-dom';
//required components
import AddExpensePage from '../components/AddExpensePage';
import EditExpensePage from '../components/EditExpensePage';
import { createBrowserHistory } from 'history';
import ExpenseDashboardPage from '../components/ExpenseDashboardPage';
import LoginPage from '../components/LoginPage';
import NotFoundPage from '../components/NotFoundPage';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
export const browserHistory = createBrowserHistory();

//create Router with createBrowserRouter react-router v6
// const router = createBrowserRouter([
//     {
//         path: "/",
//         element: <ExpenseDashboardPage />

//     },
//     {
//         path: "/create",
//         element: <AddExpensePage />
//     },
//     {
//         path: "/edit",
//         element: <EditExpensePage />
//     },
//     {
//         path: "/help",
//         element: <HelpPage />
//     },
//     {
//         path: '*',
//         element: <NotFoundPage />
//     }
// ])

const AppRouter = () => (
    <HistoryRouter history={browserHistory} >
        <Routes >
            <Route path='/' element={<PublicRoute redirect="/dashboard"><LoginPage /></PublicRoute>} />
            <Route path='/dashboard' element={<PrivateRoute component={ExpenseDashboardPage} redirect={"/"} />} />
            <Route path='/create' element={<PrivateRoute component={AddExpensePage} redirect={"/"} />} />
            <Route path='/edit/:id' element={<PrivateRoute component={EditExpensePage} redirect={"/"} />} />
            <Route path='*' element={<NotFoundPage />} />
        </Routes>
    </HistoryRouter>
);

// const AppRouter = () => (
//     <BrowserRouter>
//         <CustomNavigator />
//         <App />
//     </BrowserRouter>
// )
export default AppRouter;
