import React, { useState } from "react";
import { connect } from "react-redux";
import { setTextFilter, sortByAmount, sortByDate, setStartDate, setEndDate } from '../actions/filters';
import { DateRangePicker } from "react-dates";
const ExpenseListFilters = (props) => {
    const [focused, setFocused] = useState(null);
    return (
        <div className="content-container">
            <div className="input-group">
            <div className="input-group__item">
                    <input
                        className="text-input"
                        type="text"
                        value={props.filters.searchTerm}
                        placeholder="Search expenses"
                        onChange={(e) => {
                            //Use debouncer later
                            props.setTextFilter(e.target.value)
                        }} />
                </div>
                <div className="input-group__item">
                    <select 
                        className="select"
                        name="sortBy"
                        value={props.filters.sortBy}
                        onChange={(e) => {
                            const sortBy = e.target.value;
                            console.log(sortBy);
                            if (sortBy === 'amount') {
                                props.sortByAmount()
                            } else {
                                props.sortByDate();
                            }
                        }}>
                        <option value="date">Date</option>
                        <option value="amount">Amount</option>
                    </select>
                   
                   
                </div>
                <div className="input-group__item">
                    <DateRangePicker
                        startDate={props.filters.startDate}
                        endDate={props.filters.endDate}
                        onDatesChange={({ startDate, endDate }) => {
                            props.setStartDate(startDate)
                            props.setEndDate(endDate)
                        }}
                        focusedInput={focused}
                        onFocusChange={focused => setFocused(focused)}
                        numberOfMonths={1}
                        isOutsideRange={() => false}
                        showClearDates={true}
                        startDateId='idStart'
                        endDateId="idEnd"
                    />
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setTextFilter: searchTerm => dispatch(setTextFilter(searchTerm)),
    setStartDate: startDate => dispatch(setStartDate(startDate)),
    setEndDate: endDate => dispatch(setEndDate(endDate)),
    sortByAmount: () => dispatch(sortByAmount()),
    sortByDate: () => dispatch(sortByDate())

})
const mapStoreStateToComponentProps = (state) => ({
    filters: state.filters
})
export { ExpenseListFilters }
export default connect(mapStoreStateToComponentProps, mapDispatchToProps)(ExpenseListFilters);